$(function () {
  svg4everybody();


  if ($('.js-preloader').length) {
    var timerId = 0;
    var load = false;
    timerId = setInterval(function(){
      var load = $('.js-preloader').hasClass('hidden');
      if (load) {
        $('body,html').animate({scrollTop: 0}, 0);
        setTimeout(function(){
          loadPage();
        },2000)
        clearInterval(timerId);
      }
    }, 500)
  } else {
    $('body,html').animate({scrollTop: 0}, 0);
    loadPage();
    $('body').css('overflow', 'auto');
  }

  $('.js-video-bg').videoBG({
    mp4:'video/video.mp4',
    ogv:'video/video.ogv',
    webm:'video/video.webm',
    poster:'video/video.jpg',
    opacity:1,
    fullscreen:true,
    mute: false,
  });

  function loadPage(){

    $('.screen1, header').addClass('active');

    // mask phone
    $('input[name="phone"]').mask('+7 (999) 999-99-99');

    // slider
    var slider = $('.js-slider')
    slider.owlCarousel({
      items: 1,
      nav: false,
      dots: false,
      dotsEach: false,
      loop: true,
      margin: 5,
      smartSpeed: 550,
      autoplay: true,
      autoplayTimeout: 6000,
      autoplayHoverPause: true,
      animateIn: 'fadeIn',
    });
    $('.sliderNav .next').click(function() {
      slider.trigger('next.owl.carousel');
    })
    $('.sliderNav .prev').click(function() {
      slider.trigger('prev.owl.carousel');
    })


    $('.js-logo').click(function(e){
      e.preventDefault();
      $('body,html').animate({
        scrollTop: 0
      }, 500);
    })

    $(window).on("scroll", onScroll);

    $('.js-menu .js-link, .mouse').on('click', function(e){
      e.preventDefault();
      $(window).off("scroll");
      var id = $(this).attr('href');
      $('.js-menu .js-link').removeClass('active');
      $(id).addClass('active');
      $(this).addClass('active');
      $('.js-gamb').trigger('click');
      var
        target = this.hash,
        menu = target;

        $target = $(target);
        $('html, body').stop().animate({
          'scrollTop': $target.offset().top+2
        }, 500, 'swing', function () {
          //window.location.hash = target;
          $(window).on("scroll", onScroll);
        });

        $('header').addClass('noFirst');
        $('.js-screen1').fadeOut();
    })

    function onScroll(event){
      var scrollPos = $(document).scrollTop() + $('header').height();
      $('.js-menu .js-link').each(function () {
        var currLink = $(this);
        var refElement = $(currLink.attr("href"));
        if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
            $('.js-menu .js-link').removeClass("active");
            currLink.addClass("active");
            var id = $('.js-menu .js-link.active').attr('href');
            $('.screen').removeClass('active')
            $(id).addClass('active current');
        }
        else{
            currLink.removeClass("active");
        }
      });

      // header
      var s1 = $('.js-screen1').height() - $('header').height()*2;
      if ($(window).scrollTop() > s1) {
        $('header').addClass('noFirst');
        $('.js-screen1').fadeOut();
      } else {
        $('header').removeClass('noFirst');
        $('.js-screen1').fadeIn();
      }


      var screen2 = $('#direction').offset().top + 300,
          screen_services = $('#services').offset().top + 300,
          screen3 = $('#why').offset().top + 300,
          screen4 = $('#portfolio').offset().top + 300,
          screen5 = $('#partners').offset().top + 300,
          screen6 = $('#contacts').offset().top + 300,
          sctop = $(window).scrollTop(),
          winh = $(window).height(),
          ys2 = screen2 - sctop - winh,
          yservices = screen_services - sctop - winh,
          ys3 = screen3 - sctop - winh,
          ys4 = screen4 - sctop - winh,
          ys5 = screen5 - sctop - winh;
          ys6 = screen6 - sctop - winh;

      if (ys2 > 0 || -ys2 > winh) {} else {
        $('#direction').addClass('visible');
      }
      if (yservices > 0 || -yservices > winh) {} else {
        $('#services').addClass('visible');
      }
      if (ys3 > 0 || -ys3 > winh) {} else {
        $('#why').addClass('visible');
      }
      if (ys4 > 0 || -ys4 > winh) {} else {
        $('#portfolio').addClass('visible');
      }
      if (ys5 < 0 || -ys5 > winh) {} else {
        $('#partners').addClass('visible');
      }
      if (ys6 < 0 || -ys6 > winh) {} else {
        $('#contacts').addClass('visible');
      }
    }

    function preventDefault(e) {
      e = e || window.event;
      if (e.preventDefault)
          e.preventDefault();
      e.returnValue = false;
    }
    function disableScroll() {
      if (window.addEventListener) // older FF
          window.addEventListener('DOMMouseScroll', preventDefault, false);
      window.onwheel = preventDefault; // modern standard
      window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
    }
    function enableScroll() {
        if (window.removeEventListener)
            window.removeEventListener('DOMMouseScroll', preventDefault, false);
        window.onmousewheel = document.onmousewheel = null;
        window.onwheel = null;
    }



    var isAnimate = false;
    $(window).bind("mousewheel", function(event, delta) {
      if (isAnimate) {
        event.stopPropagation();
        event.preventDefault();
      }
      if (delta < 0 && $(window).scrollTop() < $(window).height() && !isAnimate){
        isAnimate = true;
        $('body,html').stop(true, false).animate({
          scrollTop: $('.screen2').offset().top
        }, 500, 'swing', function(){
          $('.js-screen1, header').removeClass('hidden');
          isAnimate = false;
        })
      }
    });

    // wow
    wow = new WOW({
      boxClass:     'wow',
      offset:       200,
      mobile:       false,
    })
    wow.init();

    $('.js-consult').click(function(e){
      e.preventDefault();
       $('body,html').animate({
        scrollTop: $('#contacts').offset().top
      }, 500);
    });
    $('.js-sliderMore').click(function(e){
      e.preventDefault();
       $('body,html').animate({
        scrollTop: $('#direction').offset().top
      }, 500);
    });
    $('.js-sliderMore--3').click(function(e){
      e.preventDefault();
       $('body,html').animate({
        scrollTop: $('#services').offset().top
      }, 500);
    });


    /*
    $('.portfolioItem').click(function(e){
      e.preventDefault();
      var link = $(this).attr('href');
      $.ajax({
        url: link,
        success: function(data){
          $('body').css({'overflow':'hidden'}).append( data );
          portfolio();
        }
      });
    });
    */


    // portfolio
    function portfolio(){
      var portfolio = $('.js-popupSlider'),
          portfolioParent = portfolio.closest('.popupSlider'),
          indexAll = 0;
      $('.portfolioItem').click(function(){

        $('.js-popupSlider').trigger('refresh.owl.carousel');
        $('body').css({'overflow':'hidden'});
        $('.js-popup').fadeIn();
        portfolioParent.find('.js-numbers .active').text('1')
        portfolioParent.find('.js-numbers .active').text(portfolio.find('.active').index()+1)
        portfolioParent.find('.js-numbers .all').text(portfolio.find('.owl-item').length)
        portfolioParent.find('.prev').addClass('tolast');
        indexAll = portfolio.find('.owl-item').length - 1;
      });
      portfolio.owlCarousel({
        items: 1,
        nav: false,
        dots: false,
        //loop: true,
        smartSpeed: 550,
        autoplay: false,
        autoplayTimeout: 4000,
        autoplayHoverPause: true,
      });
      portfolio.on('translated.owl.carousel', function(event) {
        portfolioParent.find('.js-numbers .active').text(portfolio.find('.active').index()+1)
        portfolioParent.find('.js-numbers .all').text(portfolio.find('.owl-item').length)
        if (portfolio.find('.owl-item').last().hasClass('active')) {
          portfolioParent.find('.next').addClass('tofirst');
        }
        else if ($(this).find('.owl-item').first().hasClass('active')) {
          portfolioParent.find('.prev').addClass('tolast');
          indexAll = portfolio.find('.owl-item').length - 1;
        } else {
          portfolioParent.find('.next').removeClass('tofirst');
          portfolioParent.find('.prev').removeClass('tolast');
        }
      })
      $('.sliderNavPopup .next').click(function() {
        portfolio.trigger('next.owl.carousel');
      })
      $('.sliderNavPopup .prev').click(function() {
        portfolio.trigger('prev.owl.carousel');
      })
      $('body').on('click', '.tofirst', function(){
        $(this).removeClass('tofirst')
        portfolio.trigger('to.owl.carousel', [0, 50, true]);
      })
      $('body').on('click', '.tolast', function(){
        $(this).removeClass('tolast')
        portfolio.trigger('to.owl.carousel', [indexAll, 50, true]);
      })
      $('.js-popup-close').click(function(){
        $('.js-popup').fadeOut();
        $('body').css({'overflow':'auto'});
        $('.js-popup').remove();
      })
    }
  }



  // validation
  var required = 'Поле обязательно для заполнени', incorrect = 'Поле заполнено некорректно';

  function showDataContent($obj, timeout) {
  setTimeout(function() { $obj.val($obj.attr('data-content'));  }, timeout || 1500);
  }

  $('[name="phone"], [name="name"], [name="mail"], [name="message"]').val('').blur(function() {
    $(this).attr('data-content', this.value);
    var $this = $(this), $submit = $(this).closest('form').find('button'), er;
    if(this.value == '') {
      $this.addClass('error');
      this.value = required;
      showDataContent($this);
      return;
    }
    if(this.name == 'name' && !/^\s*[a-zA-Z0-9а-яА-ЯёЁ]{2}([a-zA-Z0-9а-яА-ЯёЁ\s]*[a-zA-Z0-9а-яА-ЯёЁ])?\s*$/.test(this.value)) {
      $this.addClass('error');
      this.value = incorrect;
      showDataContent($this);
      return;
    }
    if(this.name == 'message' && !/^\s*[a-zA-Z0-9а-яА-ЯёЁ]{2}([a-zA-Z0-9а-яА-ЯёЁ\s]*[a-zA-Z0-9а-яА-ЯёЁ])?\s*$/.test(this.value)) {
      $this.addClass('error');
      this.value = incorrect;
      showDataContent($this);
      return;
    }
    if(this.name == 'mail' && !/^\s*[a-zA-Z0-9][a-zA-Z0-9\._\-]*[a-zA-Z0-9]@[a-zA-Z0-9][a-zA-Z0-9\.\-]*[a-zA-Z0-9]\.[a-zA-Z]{2,7}\s*$/.test(this.value)) {
      $this.addClass('error');
      this.value = incorrect;
      showDataContent($this);
      return;
    }
    var isError = $(this).closest('form').find('.virgin').length > 0 || $(this).closest('form').find('.error').length > 0;
    $this.addClass('success');
  }).focus(function() {
    $(this).removeClass('virgin');
    $(this).removeClass('error success');
    if(this.value == required) {
      this.value = '';
    }
    if(this.value == incorrect) {
      showDataContent($(this), 0);
    }
  });

  $('form').submit(function() {
     $('input, textarea').each(function() {
       if($(this).val() == ''){
        $(this).addClass('error');
        $(this).val(required);
        showDataContent($(this));
       }
     });
     if($(this).find('.error').length > 0) {
       return false;
     }
   //$('.js-close').trigger('click'); // это потом удалить
   $.ajax({ url: 'ajax.php', data: $(this).serialize(), type: 'post', dataType: 'json',
    success: function(json) {
      if(json.status == 1) {
        //$('.js-close').trigger('click');
        $('.contactsForm .contactsFormTop').after('<div style="color:#0e9e0e;margin:0 0 10px 0">Ваше сообщение успешно отправлено</div>');
        $('input, textarea').each(function() {
            $(this).val('');
            $(this).attr('value', '');
        });
      }
    }
   });
   return false;
  });



  // mobile
  function mobile(){
    if ($(window).width() < 768) {
      $('.js-menu').appendTo('.js-gambHidden');
      $('.question').appendTo('.js-gambHidden');
      $('header').addClass('mobile');
    } else{
      $('.js-menu').insertAfter('.header .logo');
      $('.question').insertAfter('.header .js-menu');
      $('header').removeClass('mobile');
    }
  }
  mobile();
  $(window).resize(function(){
    mobile();
  })

  $('.js-gamb').click(function(){
    if ($(this).hasClass('close')) {
      $('.js-gambHidden').slideUp();
      $(this).removeClass('close');
    } else {
      $('.js-gambHidden').slideDown();
      $(this).addClass('close');
    }
  });

  $('.question__icon').on('click', function() {
    $('#zcwMiniButton').click();
    $('.zcwPopup-close').empty();
    return false;
  });

  $('.question__icon').click(function() {
    $('.zcwPopup').addClass('flx');
  });
  $('body').on('click', '.zcwPopup-form input[name="n"]', function() {
    $(this).mask("7 (999) 999-9999", {
      placeholder:"7 (___) ___-__-__"
    });
  });
  $('body').on('click','.zcwPopup-close', function() {
    $(this).parent('.zcwPopup').removeClass('flx');
  });
  
  function parallaxEffect(element, count) {
    var coordScroll = window.pageYOffset || document.documentElement.scrollTop,
        coordBottomScroll = coordScroll + window.innerHeight,
        coordElement = element.offset().top,
        countScroll = coordBottomScroll - coordElement;
    if ((coordBottomScroll >= coordElement) && (coordScroll <= (coordElement + element.height()))) {
      element.css({'transform': 'translate3d(0, ' + (countScroll / count) + 'px, 0)'});
    }
  }
  $(window).scroll(function() {
    parallaxEffect($('.screen-services__background-block'), 2.89);
  });

  $(function () {
    objectFitImages();

    new Swiper($('.partnersSlider'), {
      slidesPerView: 3,
      wrapperClass: 'partnersWrapper',
      containerModifierClass: 'partnersSlider--',
      slideClass: 'partnersSlide',
      slideActiveClass: 'partnersSlide--active',
      slideDuplicateClass: 'partnersSlide--duplicate',
      slidePrevClass: 'partnersSlide--preview',
      slideNextClass: 'partners__slide--next',
      slideDuplicateActiveClass: 'partnersSlide--duplicate-active',
      slideDuplicatePrevClass: 'partnersSlide--duplicate-prev',
      loop: false,
      // effect: 'fade',
      touchRatio: 1,
      spaceBetween: 30,
      // loopedSlides: 6,
      navigation: {
        // disabledClass: `${rootLink}__button--disabled`,
        prevEl: $('.partnersButton--prev'),
        nextEl: $('.partnersButton--next'),
      },
      // pagination: {
      //   el: `.${rootLink}__pagination`,
      //   modifierClass: `${rootLink}__pagination--`,
      //   clickableClass: `${rootLink}__pagination--clickable`,
      //   bulletClass: `${rootLink}__bullet`,
      //   bulletActiveClass: `${rootLink}__bullet--active`,
      //   clickable: true,
      // },
      breakpoints: {
        991: {
          slidesPerView: 2,
        },
        767: {
          slidesPerView: 1,
        },
      },
      a11y: {
        enabled: false,
      },
    });

    new PerfectScrollbar(document.querySelector('.portfolioContent'), {
      wheelSpeed: 1,
      suppressScrollY: true,
      suppressScrollX: false,
      wheelPropagation: true,
      minScrollbarLength: 10,
      maxScrollbarLength: 10,
      useBothWheelAxes: true,
    });
  });
});
